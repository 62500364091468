
import React from 'react'
import ReactDOM from 'react-dom'
import * as serviceWorker from './serviceWorker'

import Frontend from '@touchlay/frontend-base'
import reducers from './reducers'
import routes from './routes'
import config from './landingConfig'

ReactDOM.render(
  <Frontend config={config} reducers={reducers}>
    {routes}
  </Frontend>,
  document.getElementById('main')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
