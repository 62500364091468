
import React from 'react'

import { Switch, Route } from 'react-router-dom'
import { Error404, DevicePreview } from '@touchlay/frontend-base'

const FeragPreview = (props) =>
  <DevicePreview
    {...props} // eslint-disable-line
    configOverride={{ 'router.type': 'browser', 'router.prefixPath': '' }}
    licenseOverride='eyJpZCI6IjYwMDgzY2EzOGVlMGRlMDBjNTlhMDE5ZiIsImtleSI6Ijg5MTg1MjFhLWQ4ZWYtNDFmYS1iMjAzLWQwNzQzNzY3Y2UyMCJ9'
  />

const routes = (
  <Switch>
    <Route component={FeragPreview} path='/' />
    <Route component={Error404} />
  </Switch>
)

export default routes
