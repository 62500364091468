const config = {
  branding: {
    name: 'Presentations',
    features: [
      { name: 'interactive' },
      { name: 'customizable' },
      { name: 'unique' },
    ],
  },
}

export default config
